import React, { useState, useRef } from "react";

const Form = (props) => {
  const formRef = useRef(null);
  const scriptUrl =
    "https://script.google.com/macros/s/AKfycbzhfbxNPOHtZW9YFvfrYYcHf93gWKYQ-D2QOiFbDKHPv91V-IP4wSMc5lv7YhdjiAw2gw/exec";
  const [loading, setLoading] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    fetch(scriptUrl, {
      method: "POST",
      body: new FormData(formRef.current),
    })
      .then((res) => {
        //console.log("SUCCESSFULLY SUBMITTED")
        window.location.assign("/contact-validation/");
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  return (
    <div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
      <div className="max-w-lg mx-auto lg:max-w-none">
        <form
          className="gap-y-6 w-full"
          name="google-sheet"
          ref={formRef}
          onSubmit={handleSubmit}
        >
          <div className="pb-4">
            <label htmlFor="nom" className="sr-only">
              Nom
            </label>
            <input
              type="text"
              name="Nom"
              id="nom"
              autoComplete="name"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
              placeholder="Nom"
              required
            />
          </div>
          <div className="pb-4">
            <label htmlFor="prenom" className="sr-only">
              Prénom
            </label>
            <input
              type="text"
              name="Prénom"
              id="prenom"
              autoComplete="name"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
              placeholder="Prénom"
              required
            />
          </div>
          <div className="pb-4">
            <label htmlFor="telephone" className="sr-only">
              Téléphone
            </label>
            <input
              type="number"
              name="Téléphone"
              id="telephone"
              autoComplete="tel"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
              placeholder="Téléphone"
              required
            />
          </div>

          <div className="pb-4">
            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <input
              id="email"
              name="Email"
              type="email"
              autoComplete="email"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
              placeholder="Email"
              required
            />
          </div>

          <div className="pb-4">
            <label htmlFor="email" className="sr-only">
              Adresse de livraison
            </label>
            <input
              id="adresse_livraison"
              name="Adresse de livraison"
              type="text"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
              placeholder="Adresse de livraison"
              required
            />
          </div>

          <div className="pb-4">
            <label htmlFor="message" className="sr-only">
              Votre liste
            </label>
            <textarea
              id="message"
              name="Message"
              rows={4}
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border border-gray-300 rounded-md"
              placeholder="Votre liste"
              defaultValue={""}
              required
            />
          </div>
          <div className="pb-4">
            <input
              type="checkbox"
              id="cgu"
              name="CGU"
              className="inline mr-2 shadow-sm py-1 px-3 placeholder-gray-500 focus:ring-orange-500 focus:border-orange-500 border-gray-300 rounded-md"
              required
            />
            J'accepte les{" "}
            <a
              href="/nous-connaitre/pages-juridiques/cgu/"
              className="text-orange-500"
              target="_blank"
              rel="noreferrer"
            >
              Conditions Générales d'Utilisation
            </a>
          </div>

          <div className="hidden">
            <label htmlFor="tellepro" className="sr-only">
              Téllépro
            </label>
            <input type="text" name="tellepro" />
            <input
              type="text"
              name="Source"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
              placeholder="Source"
              value="www.artmoval-demenagements-lyon.fr"
            />
            <input
              type="text"
              name="Moyen"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
              value={props.moyen}
            />
            <input
              type="text"
              name="IdForm"
              className="block w-full shadow-sm py-3 px-4 placeholder-gray-500 focus:ring-sky-500 focus:border-sky-500 border-gray-300 rounded-md"
              value={props.destinataire}
            />
          </div>

          <div className="pb-4">
            <button
              type="submit"
              className="inline-flex items-center mr-6 rounded-md ring-1 bg-orange-600 hover:bg-orange-700 px-3.5 py-2.5 text-base font-semibold text-white shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-white"
            >
              {loading ? "Envoi en cours ..." : "Envoyer"}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Form;
