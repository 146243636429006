import React from "react";
import { Helmet } from "react-helmet";

import Header from "src/components/headers/header";
import Footer from "src/components/footers/footer";
import Form from "src/components/formulaires/formCommande";

export default function index() {
  return (
    <div className="bg-art-beige">
      <Helmet>
        <title>art Moval - Contact</title>
        <meta name="description" content="Demandes d’informations ArtMoval" />
      </Helmet>
      <header className="sticky top-0 z-50 shadow-lg bg-white">
        <Header />
      </header>

      <main className="relative">
        <div className="hidden lg:block relative bg-art-beige">
          <div className="relative max-w-7xl mx-auto py-8 px-4">
            <h1>
              <span className="mt-2 block text-3xl text-center leading-8 font-bold tracking-tight text-gray-900 sm:text-4xl">
                art Moval, boutique
              </span>
            </h1>
            <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Afin de vous assurer un déménagement serein, art Moval vous
              accompagne dans toutes les étapes.
            </p>
            <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Particuliers, professionnels, entreprises, nous assurons votre
              déménagement clés en main, partout en France, qu’il soit partiel
              ou total. En effet, un déménagement ou un transfert est souvent
              lié à un changement d’habitudes. Pour ce faire, nous vous
              conseillons et nous vous guidons jusqu’à votre emménagement.
            </p>
            <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Besoin d’une pièce en plus ? Besoin de stocker vos affaires pour
              une durée déterminée ou indéterminée ? Nous mettons également à
              votre disposition notre service de garde-meubles proche de Lyon.
            </p>
            <p className="mt-8 mb-8 text-xl text-gray-500 leading-8">
              Forts de notre expérience et de notre savoir-faire dans les
              domaines du déménagement et du garde-meubles, nous vous invitons à
              nous contacter, pour tous renseignements ou devis gratuit.
            </p>
          </div>
        </div>

        <Form />
      </main>

      <footer>
        <Footer />
      </footer>
    </div>
  );
}
