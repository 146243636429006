import React from "react";
import { MailIcon, PhoneIcon, AtSymbolIcon } from "@heroicons/react/outline";
import { encode } from "js-base64";
import ContactForm from "../contacts/commandeForm";

const encryptedMail = encode("margaux.verilhac@artmoval.fr");
const moyen = "Formulaire art Moval contact";

export default function Form() {
  return (
    <div className="relative bg-white">
      <div className="absolute inset-0">
        <div className="absolute inset-y-0 left-0 w-1/2 bg-gray-50" />
      </div>
      <div className="relative max-w-7xl mx-auto lg:grid lg:grid-cols-5">
        <div className="bg-gray-50 py-4 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
          <div className="max-w-lg mx-auto">
            <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
              Passer votre commande de produits
            </h2>

            <dl className="mt-8 text-base text-gray-500">
              <div>
                <dt className="sr-only">Adresse</dt>
                <dd>
                  <p className="mt-8 text-xl text-gray-700">
                    art Moval Déménagements
                  </p>
                  <p>76 avenue Charles de Gaulle</p>
                  <p>69160 Tassin La Demi-Lune FRANCE</p>
                </dd>
              </div>
              <div className="mt-6">
                <dt className="sr-only">Phone number</dt>
                <dd className="flex">
                  <PhoneIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <span className="ml-3">04 72 32 57 57</span>
                </dd>
              </div>
              <div className="mt-3">
                <dt className="sr-only">Email</dt>
                <dd className="flex">
                  <MailIcon
                    className="flex-shrink-0 h-6 w-6 text-gray-400"
                    aria-hidden="true"
                  />
                  <a href="mailto:tassin@artmoval.fr">
                    <span className="ml-3">
                      margaux.verilhac
                      <AtSymbolIcon className="h-5 inline" />
                      artmoval.fr
                    </span>
                  </a>
                </dd>
              </div>
            </dl>
          </div>
        </div>

        <ContactForm moyen={moyen} destinataire={encryptedMail} />
      </div>
    </div>
  );
}
